
import React, { useState, useEffect } from "react";
import { Check, ChevronsUpDown, Copy, Undo2 } from "lucide-react"
import { NavLink, useParams } from "react-router-dom"
import { FaEthereum, FaBitcoin } from "react-icons/fa";
import { SiLitecoin } from "react-icons/si";
import QRCode from "react-qr-code";


import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, Command } from "@/components/ui/command"
import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge";
import api from '@/services/api/api';

export const description = "A simple login form with email and password. The submit button says 'Sign in'."


export default function Payment() {
    let currency = "bitcoin"
    let { id } = useParams();
    const [address, setAddress] = useState("")
    useEffect(() => {
        api.post(`wallet`).then(
            (response) => {
                if (response.status == 200) {
                    api.get(`wallet/${response.data}/address`)
                        .then((r) => { console.log(r.data); setAddress(r.data) })
                        .catch((e) => console.log(e));
                }
            }
        )
            .catch((e) => console.log(e));
    }, [])

    return (
        <>
            <NavLink to="#" className="absolute p-4">
                <Undo2 />
            </NavLink>
            <div className="h-screen flex items-center justify-center p-4 sm:px-6 sm:py-0">
                <Card className="w-full max-w-sm">
                    <CardHeader>
                        <CardTitle className="text-2xl">Payment</CardTitle>
                        <CardDescription>
                            Send {currency} to this address
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        <div className="w-auto mx-8">
                            <Card className="p-4 h-full">
                                {address === "" ?
                                    <div className="shadow rounded-md p-4 mx-auto animate-pulse bg-slate-300 w-full h-full">
                                    </div>
                                    : 
                                    <QRCode
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={"bitcoin:"+address}
                                        viewBox={`0 0 256 256`}
                                    />
                                }
                            </Card>
                        </div>
                        <div className="grid gap-2">
                            <div className="flex gap-1">
                                <Input value={address} disabled />
                                <Button variant="outline" size="icon">
                                    <Copy className="h-4 w-4" />
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                    <CardFooter className="grid gap-2">
                        <div className="flex gap-1 pt-2 whitespace-nowrap">
                            <Label className="my-auto">Payment status:</Label>
                            <Badge variant="default" className="bg-green-600">Transaction pending</Badge>
                        </div>
                        <span className="text-xs">last updated: <span> 3 sec ago</span></span>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}


