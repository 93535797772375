import Layout from '@/components/Layout';
import { NavLink } from 'react-router-dom';

const NoMatch = () => {
    return (
        <Layout navbarside={false}>
            <div className='mx-4 md:mx-16'>
                <div className='w-full text-4xl'>404 not found</div>
                <NavLink to="/" className="text-blue-400">Back to home</NavLink>
            </div>
        </Layout>
    );
};

export default NoMatch;  