import { NavLink } from "react-router-dom";
import { Home, LineChart, Package, CircleUser, PanelLeft, Search, Users2 } from "lucide-react"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import NavBarTop from "./NavBarTop";
import NavBarSide from "./NavBarSide";


export default function Layout({
  navbarside = true,
  navbartop = true,
  searchbar = true,
  children = <></>
}) {

  return (navbarside ?
    <>
      <NavBarSide />
      <div className="flex flex-col gap-4 sm:pl-14">
        {navbartop && <NavBarTop searchbar={searchbar}/>}
        <div className="pt-4">
          {children}
        </div>
      </div>
    </>
    :
    <>
      {navbartop && <NavBarTop searchbar={searchbar}/>}
      < div className="pt-4">
        {children}
      </div >
    </>
  );
};
