
import * as React from "react"
import { Check, ChevronsUpDown, Undo2 } from "lucide-react"
import { NavLink, useParams } from "react-router-dom"
import { FaEthereum, FaBitcoin } from "react-icons/fa";
import { SiLitecoin } from "react-icons/si";


import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, Command } from "@/components/ui/command"
import { cn } from "@/lib/utils"

export const description =
    "A simple login form with email and password. The submit button says 'Sign in'."


export default function PaymentMethod() {
    const [open, setOpen] = React.useState(false)
    const [currency, setCurrency] = React.useState("btc")
    let { id } = useParams();

    const frameworks = [
        { value: "btc", label: "Bitcoin (btc)" },
        { value: "eth", label: "Ethereum (eth)" },
        { value: "ltc", label: "Litcoin (ltc)" },
        { value: "xrp", label: "XRP" },
        { value: "bnb", label: "binance coin (bnb)" },
        { value: "cro", label: "Crypto.com (cro)" },
        { value: "egld", label: "MultiverseX (EGLD)"}
    ]

    return (
        <>
            <NavLink to="#" className="absolute p-4">
                <Undo2 />
            </NavLink>
            <div className="h-screen flex items-center justify-center p-4 sm:px-6 sm:py-0">
                <Card className="w-full max-w-sm">
                    <CardHeader>
                        <CardTitle className="text-2xl">Payment Method</CardTitle>
                        <CardDescription>
                            Select a currency to use as a payment method.
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        <div className="flex gap-2 w-full">
                            <Button onClick={(e) => setCurrency(e.currentTarget.value)} className={(currency === "btc" ? "ring-offset-2 ring-2 " : "") + "flex-1 h-20"} variant="outline" size="lg" value="btc">
                                <FaBitcoin className="w-6 h-6" />
                            </Button>
                            <Button onClick={(e) => setCurrency(e.currentTarget.value)} className={(currency === "eth" ? "ring-offset-2 ring-2 " : "") + "flex-1 h-20"} variant="outline" size="lg" value="eth">
                                <FaEthereum className="w-6 h-6" />
                            </Button>
                            <Button onClick={(e) => setCurrency(e.currentTarget.value)} className={(currency === "ltc" ? "ring-offset-2 ring-2 " : "") + "flex-1 h-20"} variant="outline" size="lg" value="ltc">
                                <SiLitecoin className="w-6 h-6" />
                            </Button>
                        </div>

                        <div className="grid gap-2">
                            <Label >Select currency</Label>
                            <Popover open={open} onOpenChange={setOpen}>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant="outline"
                                        role="combobox"
                                        aria-expanded={open}
                                        className="justify-between"
                                    >
                                        {currency
                                            ? frameworks.find((framework) => framework.value === currency)?.label
                                            : "Select framework..."}
                                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent className="p-0">
                                    <Command>
                                        <CommandInput placeholder="Search framework..." />
                                        <CommandList>
                                            <CommandEmpty>No framework found.</CommandEmpty>
                                            <CommandGroup>
                                                {frameworks.map((framework) => (
                                                    <CommandItem
                                                        key={framework.value}
                                                        value={framework.value}
                                                        onSelect={(currentValue) => {
                                                            setCurrency(currentValue === currency ? "" : currentValue)
                                                            setOpen(false)
                                                        }}
                                                    >
                                                        <Check
                                                            className={cn(
                                                                "mr-2 h-4 w-4",
                                                                currency === framework.value ? "opacity-100" : "opacity-0"
                                                            )}
                                                        />
                                                        {framework.label}
                                                    </CommandItem>
                                                ))}
                                            </CommandGroup>
                                        </CommandList>
                                    </Command>
                                </PopoverContent>
                            </Popover>
                        </div>
                    </CardContent>
                    <CardFooter>
                        <Button className="w-full">
                            <NavLink to={`/payment/${id}`}>Go to payment</NavLink>
                        </Button>
                    </CardFooter>
                </Card>
            </div>
        </>
    )
}


