import Layout from '@/components/Layout';
import { ChevronLeft, ChevronRight, Copy, CreditCard, File, ListFilter, MoreVertical, Truck } from "lucide-react"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "@/components/ui/pagination"
import { Progress } from "@/components/ui/progress"
import { Separator } from "@/components/ui/separator"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import ProjectCard from '@/components/ProjectCard';
import { BiCategory } from "react-icons/bi";

export const description = "An orders dashboard with a sidebar navigation. The sidebar has icon navigation. The content area has a breadcrumb and search in the header. The main area has a list of recent orders with a filter and export button. The main area also has a detailed view of a single order with order details, shipping information, billing information, customer information, and payment information."

export default function Projects() {
    return (
        <Layout navbarside={false}>
            <main className="grid flex-1 items-start gap-4 md:gap-8 mx-4 sm:mx-16">
                <div className='mx-auto text-center'>
                    <h1 className='text-5xl font-medium font-[coolvetica] tracking-wide'>34 904 projets sur Blockstarter</h1>
                    <h2 className='text-lg font-normal mt-2'>Explorez des projets innovant et gamechanger</h2>
                </div>
                <Tabs defaultValue="most_popular">
                    <div className="flex items-center">
                        <TabsList>
                            <TabsTrigger value="most_popular">Most popular</TabsTrigger>
                            <TabsTrigger value="recent">Recent</TabsTrigger>
                            <TabsTrigger value="amount">Amount</TabsTrigger>
                        </TabsList>
                        <div className="ml-auto flex items-center gap-2">
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        className="h-7 gap-1 text-sm"
                                    >
                                        <BiCategory className="h-3.5 w-3.5" />
                                        <span className="sr-only sm:not-sr-only">Category</span>
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                    <DropdownMenuLabel>See Category</DropdownMenuLabel>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuCheckboxItem checked>
                                        Crypto currency
                                    </DropdownMenuCheckboxItem>
                                    <DropdownMenuCheckboxItem>
                                        Funny
                                    </DropdownMenuCheckboxItem>
                                    <DropdownMenuCheckboxItem>
                                        Healtcare
                                    </DropdownMenuCheckboxItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <Button
                                size="sm"
                                variant="outline"
                                className="h-7 gap-1 text-sm"
                            >
                                <ListFilter className="h-3.5 w-3.5" />
                                <span className="sr-only sm:not-sr-only">Filter</span>
                            </Button>
                        </div>
                    </div>
                    <TabsContent value="most_popular">
                        <div className="grid gap-x-2 gap-y-4 md:gap-y-8 md:gap-x-4 lg:gap-x-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 mt-8">
                            <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
                            <ProjectCard title="Candide career" img={"/assets/ski3.jpg"} />
                            <ProjectCard title="Le ski d'été c'est super fun" />
                            <ProjectCard />
                            <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
                            <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
                            <ProjectCard title="Candide career" img={"/assets/ski3.jpg"} />
                            <ProjectCard title="Le ski d'été c'est super fun" />
                            <ProjectCard />
                            <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
                            <ProjectCard title="Candide career" img={"/assets/ski3.jpg"} />
                            <ProjectCard title="Le ski d'été c'est super fun" />
                            <ProjectCard />
                            <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
                            <ProjectCard title="Candide career" img={"/assets/ski3.jpg"} />
                            <ProjectCard title="Le ski d'été c'est super fun" />
                            <ProjectCard />
                        </div>

                        <Pagination className='my-8'>
                            <PaginationContent>
                                <PaginationItem>
                                    <PaginationPrevious to="" />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink to="">1</PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink to="">2</PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationEllipsis />
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationLink to="">78</PaginationLink>
                                </PaginationItem>
                                <PaginationItem>
                                    <PaginationNext to="" />
                                </PaginationItem>
                            </PaginationContent>
                        </Pagination>


                    </TabsContent>
                </Tabs>
            </main>
        </Layout>
    )
}
