import { NavLink } from "react-router-dom";
import { Home, LineChart, Package, Binary, Settings, Users2 } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"

export default function NavBarSide() {
  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
        <NavLink
          to="/"
          className={"group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"}
        >
          <Home className="h-5 w-5 transition-all hover:scale-110 group-hover:scale-100" />
        </NavLink>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger >
              <NavLink
                to="/projects"
                className={({ isActive }) => (isActive ? "bg-accent " : "") + "flex h-9 w-9 items-center justify-center rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8"}
              >
                <Package className="h-5 w-5 transition-all hover:scale-110 group-hover:scale-100" />
                <span className="sr-only">Projects</span>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right">Projects</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger >
              <NavLink
                to="/community"
                className={({ isActive }) => (isActive ? "bg-accent " : "") + "flex h-9 w-9 items-center justify-center rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8"}
              >
                <Users2 className="h-5 w-5 transition-all hover:scale-110 group-hover:scale-100" />
                <span className="sr-only">Community</span>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right">Community</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger >
              <NavLink
                to="/analytics"
                className={({ isActive }) => (isActive ? "bg-accent " : "") + "flex h-9 w-9 items-center justify-center rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8"}
              >
                <LineChart className="h-5 w-5 transition-all hover:scale-110 group-hover:scale-100" />
                <span className="sr-only">Analytics</span>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right">Analytics</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </nav>
      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger >
              <NavLink
                to="/settings"
                className={({ isActive }) => (isActive ? "bg-accent " : "") + "flex h-9 w-9 items-center justify-center rounded-lg text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8"}
              >
                <Settings className="h-5 w-5 transition-all hover:scale-110 group-hover:scale-100" />
                <span className="sr-only">Settings</span>
              </NavLink>
            </TooltipTrigger>
            <TooltipContent side="right">Settings</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </nav>
    </aside>
  );
};