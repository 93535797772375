import { NavLink, Route, Link, Routes, useLocation } from 'react-router-dom';

import { Home, LineChart, Package, CircleUser, PanelLeft, Search, Users2 } from "lucide-react"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import logo from '@/logo.png';


export default function NavBarTop(props: any) {
  const location = useLocation();
  const { hash, pathname, search } = location;
  var excludePaths = []
  if (pathname === "/")
    excludePaths.push("/")

  const routes: BreadcrumbsRoute[] = [];
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: excludePaths });

  return (
    <div className="sm:pt-4">
      <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
        <Sheet>
          <SheetTrigger asChild>
            <Button size="icon" variant="outline" className="sm:hidden">
              <img
                src={logo}
                alt="logo"
                className="object-cover h-10 w-10 rounded-sm"
              />
              <span className="sr-only">Toggle Menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="sm:max-w-xs">
            <nav className="grid gap-6 text-lg font-medium">
              <NavLink
                to="/"
                className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
              >
                <Home className="h-5 w-5 transition-all group-hover:scale-110" />
                <span className="sr-only">Home</span>
              </NavLink>
              <NavLink
                to="/projects"
                className={({ isActive }) => (isActive ? "" : "text-muted-foreground hover:text-foreground ") + "flex items-center gap-4 px-2.5 text-foreground"}
              >
                <Package className="h-5 w-5" />
                Projects
              </NavLink>
              <NavLink
                to="/community"
                className={({ isActive }) => (isActive ? "" : "text-muted-foreground hover:text-foreground ") + "flex items-center gap-4 px-2.5 text-foreground"}
              >
                <Users2 className="h-5 w-5" />
                Community
              </NavLink>
              <NavLink
                to="/settings"
                className={({ isActive }) => (isActive ? "" : "text-muted-foreground hover:text-foreground ") + "flex items-center gap-4 px-2.5 text-foreground"}
              >
                <LineChart className="h-5 w-5" />
                Settings
              </NavLink>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="">
          <NavLink to="/" className="hidden md:flex items-end gap-2">
            <img
              src={logo}
              alt="logo"
              className="object-cover h-12 md:h-14 rounded-sm"
            />
            <div className="font-black text-3xl flex mr-4 font-[coolvetica] tracking-[.10rem]">
              Blockstarter
            </div>
          </NavLink>
          {/* <Breadcrumb className="hidden md:flex mt-3">
            <BreadcrumbList>
              {breadcrumbs.map(({ match, breadcrumb }, i) =>
                <>
                  {i > 0 ? <BreadcrumbSeparator /> : ""}
                  <BreadcrumbItem key={match.pathname}>
                    {i + 1 == breadcrumbs.length ?
                      <BreadcrumbPage>
                        <span>{breadcrumb}</span>
                      </BreadcrumbPage>
                      :
                      <BreadcrumbLink asChild>
                        <NavLink to={match.pathname} >{breadcrumb}</NavLink>
                      </BreadcrumbLink>
                    }
                  </BreadcrumbItem>
                </>
              )}
            </BreadcrumbList>
          </Breadcrumb> */}
        </div>

        {props.searchbar &&
          <div className="relative ml-auto flex-1 md:grow-0">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
            />
          </div>
        }
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className={`overflow-hidden rounded-full ${props.searchbar ? "": "ml-auto"}`}
            >
              <CircleUser className="h-5 w-5" />
              <span className="sr-only">Toggle user menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Support</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </header>
    </div>
  );
};
