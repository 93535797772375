import Layout from '@/components/Layout';

import {
  Activity,
  ArrowUpRight,
  CircleUser,
  CreditCard,
  DollarSign,
  Menu,
  Package2,
  Search,
  Users,
} from "lucide-react"

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { NavLink } from 'react-router-dom';
import ProjectCard from '@/components/ProjectCard';
import { FaChevronCircleRight, FaChevronRight } from 'react-icons/fa';
import api from '@/services/api/api';
import React, { useState, useEffect } from "react";
import ProjectCard2 from '@/components/ProjectCard_old';

export const description =
  "An application shell with a header and main content area. The header has a navbar, a search input and and a user nav dropdown. The user nav is toggled by a button with an avatar image."

export default function Home() {

  useEffect(() => {
    api.get(`ping`)
      .then(
        (response) => {
          if (response.status == 200) {
            console.log(response.data)
          }
        }
      )
      .catch(
        (e) => console.log(e)
      );
  })

  return (
    <Layout navbarside={false} navbartop={true} searchbar={false}>
      <main className="flex flex-1 flex-col gap-x-4 gap-y-3 md:gap-x-8 md:gap-y-8">
        {/* <div className="font-black text-9xl hidden md:flex">
            Block-starter
          </div> */}

        <div className='bg-bslogo rounded-md mt-4 mx-4 md:mx-16'>
          <h1 className='text-4xl md:text-7xl px-12 md:px-24 pt-12 md:pt-24 pb-8 font-medium text-center font-[coolvetica] tracking-wide'>
            Libérez votre créativité et lancez vos projets grâce au crowdfunding
          </h1>
          <h2 className='text-lg px-12 md:px-24 pb-12 text-center '>
            Nous avons déjà aidé 29 681 projets culturels et entrepreneuriaux à voir le jour. Et si c’était votre tour ?
          </h2>

          <div className="relative my-auto w-full px-12 md:px-20 pb-8 md:pb-20">
            <Search className="absolute left-0 md:left-5 top-5 h-6 w-6 text-muted-foreground mx-20" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full rounded-full bg-background pl-16 md:pl-14 h-16"
            />
          </div>
        </div>

        <div className='grid md:grid-cols-2 gap-4 md:gap-x-16 mx-4 md:mx-16 mt-8 '>
          <div className='md:row-span-3 flex flex-col'>
            <div className='text-md flex-none flex gap-2 justify-between mb-4'>
              <span>Weekly IA selection</span>
            </div>
            <ProjectCard full={true} title="Candide ski" img={"/assets/ski2.jpg"} className="grow h-[500px]" showdesc={true} />
          </div>
          <div className='text-md flex gap-2 justify-between'>
            <span>Most popular</span>
            <NavLink to="/projects" className={" text-xs text-blue-600 flex gap-1 my-auto"}>
              Voir tous les projets
              <FaChevronRight className='my-auto' />
            </NavLink>
          </div>
          <ProjectCard full={true} title="Candide ski" img={"/assets/ski.jpg"} className="grow h-[250px]" showdesc={true} />
          <ProjectCard full={true} title="Candide career" img={"/assets/ski3.jpg"} className="grow h-[250px]" showdesc={true} />
        </div>


        <div className='flex gap-4 md:gap-16 bg-slate-100 p-8 mt-8 py-16'>
          <div className='ms-4 md:ms-16'>
            <img
              src={"/assets/comu.jpg"}
              alt="projet"
              className="object-cover h-52 w-full "
            /></div>
          <div className='flex gap-4 flex-col align-center my-auto me-4 md:me-16'>
            <h2 className='font-[coolvetica] text-2xl'>Blockstarter community</h2>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec orci neque, pulvinar at orci porta, fermentum malesuada risus. Vivamus id felis dolor.</span>
          </div>
        </div>


        <div className='text-md mt-8 flex gap-2 justify-between mx-4 md:mx-16'>
          <span>Les nouveautés qui nous plaisent</span>
          <NavLink to="/projects" className={" text-xs text-blue-600 flex gap-1 my-auto"}>
            Voir tous les projets
            <FaChevronRight className='my-auto' />
          </NavLink>
        </div>
        <div className="grid gap-x-2 gap-y-4 md:gap-y-8 md:gap-x-4 lg:gap-x-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 mx-4 md:mx-16">
          <ProjectCard title="Candide ski" img={"/assets/ski2.jpg"} />
          <ProjectCard title="Candide career" img={"/assets/ski3.jpg"} />
          <ProjectCard title="Le ski d'été c'est super fun" />
          <ProjectCard />
        </div>


        <div className='flex gap-4 md:gap-16 bg-slate-800 text-white p-8 mt-8'>
          <div className='flex gap-4 flex-col align-center my-auto md:mx-16'>
            <h2 className='font-[coolvetica] text-xl'>Sur blockstarter</h2>
            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec orci neque, pulvinar at orci porta, fermentum malesuada risus. Vivamus id felis dolor.</span>
            <div className="grid gap-4 md:gap-8 lg:grid-cols-1 mt-8">
              <Card x-chunk="dashboard-01-chunk-0">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Total Revenue
                  </CardTitle>
                  <DollarSign className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">$45,231.89</div>
                  <p className="text-xs text-muted-foreground">
                    +20.1% from last month
                  </p>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-1">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    Participant
                  </CardTitle>
                  <Users className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">20 030 350</div>
                  <p className="text-xs text-muted-foreground">
                    +180.1% from last month
                  </p>
                </CardContent>
              </Card>
              <Card x-chunk="dashboard-01-chunk-3">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">Projet Financé</CardTitle>
                  <Activity className="h-4 w-4 text-muted-foreground" />
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">573</div>
                  <p className="text-xs text-muted-foreground">
                    +201 since last hour
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

      </main>
    </Layout>
  )
}
